<template>
  <b-container fluid>
       <b-row>
            <template>
                <div class="mb-2 d-flex justify-content-end"><b-button variant="primary" @click="pdfExport" size="sm"><i class="ri-printer-fill text-right"></i>{{
                $t('globalTrans.export_pdf') }} </b-button></div>
            </template>
            <template>
                <b-row>
                    <b-col md="12">
                        <table class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 20%">{{ $t('stock_management.brand')}}</th>
                                <td style="width: 30%">{{ brandName(items.contract_no_id) }}</td>
                                <th style="width: 20%">{{ $t('stock_management.contract_no')}}</th>
                                <td style="width: 30%">{{ contractNumber(items.contract_no_id) }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('stock_management.stock_in_id')}}</th>
                                <td style="width: 30%">{{ items.stock_id }}</td>
                                <th style="width: 20%">{{ $t('stock_management.stock_in_date')}}</th>
                                <td style="width: 30%">{{ items.stock_in_date | dateFormat }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{$t('stock_management.regional_office')}}</th>
                                <td style="width: 30%">{{currentLocale === 'en'? items.regional_office : items.regional_office_bn}}</td>
                                <th style="width: 20%">{{$t('stock_management.warehouse')}}</th>
                                <td style="width: 30%">{{ currentLocale === 'en'? items.warehouse_name_en : items.warehouse_name_bn}} </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
                 <b-row>
                  <b-col md="12">
                <!-- &&&&&&&&&&&&&&Manual Info Details Start&&&&&&&&&&&&& -->
                    <div class="table-wrapper table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr class="bg-primary">
                                    <th scope="col" style="width: 10%;" class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                                    <th scope="col" class="text-center">{{ $t('stock_management.item_category') }}</th>
                                    <th scope="col" class="text-center">{{ $t('stock_management.item') }}</th>
                                    <th scope="col" class="text-center">{{ $t('stock_management.quantity') }}</th>
                                </tr>
                            </thead>
                        <tbody>
                            <template v-if="arrStockItemData && arrStockItemData.length > 0">
                                <tr v-for="(item, index) in arrStockItemData" :key="index">
                                    <td  class="text-center">{{ $n(index+1) }}</td>
                                    <td class="text-center">{{ categoryName(item.item_category_id) }}</td>
                                    <td class="text-center">{{ itemName(item.item_id) }}</td>
                                    <td class="text-center">{{ $n(item.quantity) }}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                </tr>
                            </template>
                        </tbody>
                        </table>
                    </div>
                <!-- &&&&&&&&&&&&&&Manual Info Details End&&&&&&&&&&&&&&& -->
                  </b-col>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { initialStockDetails } from '../../../api/routes'
export default {
    props: ['items'],
    components: {
    },
    created () {
      this.arrStockItemData = this.items.indetails
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
            arrStockItemData: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        categoryName (catId) {
            const cateObj = this.$store.state.LicenseRegistrationService.commonObj.itemCategoryList.find(item => item.value === catId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        // async pdfExport () {
        //     const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.id)
        //     const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
        //     if (service !== undefined) {
        //         if (service.office_type_id) {
        //             params.office_type_id = service.office_type_id
        //         }
        //         if (service.office_id) {
        //             params.office_id = service.office_id
        //         }
        //     }
        //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        //     const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, initialStockDetails + '/' + this.id, params)
        //     var blob = new Blob([result], {
        //         type: 'application/pdf'
        //     })
        //     var url = window.URL.createObjectURL(blob)
        //     window.open(url).print()
        //     this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        //     },
            async pdfExport () {
            // this.pdfLoading = true
                const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, id: this.items.id })
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, initialStockDetails, params)
                var blob = new Blob([result], {
                    type: 'application/pdf'
                    })
                    // this.pdfLoading = false
                    var url = window.URL.createObjectURL(blob)
                    window.open(url).print()
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
        itemName (itemId) {
            const itemObj = this.$store.state.LicenseRegistrationService.commonObj.itemList.find(item => item.value === itemId)
            if (itemObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return itemObj.text_bn
                } else {
                    return itemObj.text_en
                }
            }
        },
        getShortageReasons (itemId) {
            const itemObj = this.$store.state.LicenseRegistrationService.commonObj.shortageReasonsList.find(item => item.value === itemId)
            if (itemObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return itemObj.text_bn
                } else {
                    return itemObj.text_en
                }
            } else {
                return ''
            }
        },
        contractNumber (id) {
            const contractObj = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.find(item => item.value === id)
            if (contractObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return contractObj.contract_no
                } else {
                    return contractObj.contract_no
                }
            }
        },
        brandName (id) {
            const brandObj = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.find(item => item.value === id)
            if (brandObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return brandObj.text_bn
                } else {
                    return brandObj.text_en
                }
            }
        }
    }
}

</script>
